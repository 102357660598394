.app__container {
	min-height: 100vh;
	display: flex;
}

.full__width {
	width: 100%;
}

.space__right {
	margin-right: 40px;
}
