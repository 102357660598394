.loader-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}